import { render, staticRenderFns } from "./AgeAndGender.vue?vue&type=template&id=dcc16934"
import script from "./AgeAndGender.vue?vue&type=script&lang=js"
export * from "./AgeAndGender.vue?vue&type=script&lang=js"
import style0 from "./AgeAndGender.vue?vue&type=style&index=0&id=dcc16934&prod&lang=stylus"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports