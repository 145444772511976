<template>
  <apex-chart
    type="bar"
    :height="height || 284"
    :options="options"
    :series="series"
    class="chart-age-and-gender"
  />
</template>

<script>
// Import node packages
import ApexChart from 'vue-apexcharts'
import merge from 'lodash/merge'

// Import default configuration option for chart
import apexChartBaseOptions from '@/json/apexChartBaseOptions.js'

// Export the SFC
export default {
  // Name of the component
  name: "AgeAndGender",

  // Register the components
  components: {
    ApexChart
  },

  // Accept incoming data from parent
  props: {
    audienceData: {
      type: Object,
      required: true
    },

    height: {
      type: [String, Number],
      required: false,
      default: null
    }
  },

  // Define readonly computable properties
  computed: {
    /**
     * Get the series data for chart
     *
     * @returns {Array}
     */
    series() {
      return [
        {
          name: "Male",
          color: "#EB4D4B",
          data: this.audienceData?.age?.male ?? [],
        },
        {
          name: "Female",
          color: "#2196F3",
          data: this.audienceData?.age?.female ?? [],
        },
      ]
    },

    /**
     * Get chart's configuration options object
     *
     * @returns {Object}
     */
    options() {
      return merge(apexChartBaseOptions(), {
        chart: {
          toolbar: {
            show: false
          }
        },
        stroke: {
          width: 5,
        },
        yaxis: {
          type: "numeric",
          labels: {
            formatter: (val) => numeral.fPercent(val),
          },
        },
        plotOptions: {
          bar: {
            barHeight: "42%",
            horizontal: false,
            columnWidth: "42%",
            borderRadius: 4,
          },
        },
        xaxis: {
          type: "category",
        },
        legend: {
          labels: {
            colors: [host.theme.color.text],
            useSeriesColors: false,
          },
        }
      })
    }
  }
}
</script>

<style lang="stylus">
.chart-age-and-gender
  .apexcharts-legend.apexcharts-align-right.apx-legend-position-top
    right 20px !important
    top 0 !important
</style>